import React, { Component } from 'react';

class ContactUs extends Component {
    render(){
     
      return (
        <div class="route-container">
            <h1>Contact Us</h1>
        </div>
      )
    }
}

export default ContactUs;