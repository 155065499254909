import "mapbox-gl/dist/mapbox-gl.css"
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css"
import React, { Component } from 'react'
import MapGL, {GeolocateControl, Marker } from 'react-map-gl';
import DeckGL, { GeoJsonLayer } from "deck.gl";
import Geocoder from "react-map-gl-geocoder";
import mapboxgl from 'mapbox-gl';


// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const token='pk.eyJ1IjoiYnJhbmRvbmV6em8iLCJhIjoiY2tuN3VzYjlyMDMxbzJ2cGptcWpjbHRjcCJ9.AbmDER6xDAhV2FdsV_CqlA';


const geolocateStyle = {
    float: 'right',
    margin: '50px',
    padding: '10px'
  };


class Map extends Component {
  state = { 
    viewport :{
      latitude: 20,
      longitude: -95,
      zoom: 2.5
    },
    searchResultLayer: null
  }

  mapRef = React.createRef()

  handleViewportChange = viewport => {
    this.setState({
      viewport: { ...this.state.viewport, ...viewport }
    })
  }
  // if you are happy with Geocoder default settings, you can just use handleViewportChange directly
  handleGeocoderViewportChange = viewport => {
    const geocoderDefaultOverrides = { transitionDuration: 1000 };

    return this.handleViewportChange({
      ...viewport,
      ...geocoderDefaultOverrides
    });
  };

  handleOnResult = event => {
    this.setState({
      searchResultLayer: new GeoJsonLayer({
        id: "search-result",
        data: event.result.geometry,
        getFillColor: [255, 0, 0, 128],
        getRadius: 1000,
        pointRadiusMinPixels: 10,
        pointRadiusMaxPixels: 10
      })
    })
  }

    render(){
      const { viewport, searchResultLayer} = this.state
      return (
        <div class="map-container" style={{ height: '100vh'}}>
          <MapGL 
            ref={this.mapRef}
            {...viewport}
            mapStyle="mapbox://styles/brandonezzo/ck5nctd973qjb1ilgtws6agnn"
            width="100%"
            height="100%"
            onViewportChange={this.handleViewportChange}
            mapboxApiAccessToken={token}
            >
                
              <Geocoder 
                mapRef={this.mapRef}
                onResult={this.handleOnResult}
                onViewportChange={this.handleGeocoderViewportChange}
                mapboxApiAccessToken={token}
                position='top-left'
              />

              <Marker latitude={43.78} longitude={-72.41} offsetLeft={-20} offsetTop={-10}>
              <div class="pin"><h4>5</h4></div>
              </Marker>

              <Marker latitude={40.78} longitude={-75.41} offsetLeft={-20} offsetTop={-10}>
              <div class="pin"><h4>3</h4></div>
              </Marker>

              <Marker latitude={38.78} longitude={-74.41} offsetLeft={-20} offsetTop={-10}>
              <div class="pin"><h4>1</h4></div>
              </Marker>

              <Marker latitude={36.78} longitude={-75.41} offsetLeft={-20} offsetTop={-10}>
              <div class="pin"><h4>7</h4></div>
              </Marker>

              <Marker latitude={34.78} longitude={-78.41} offsetLeft={-20} offsetTop={-10}>
              <div class="pin"><h4>0</h4></div>
              </Marker>

              <Marker latitude={32.78} longitude={-79.41} offsetLeft={-20} offsetTop={-10}>
              <div class="pin"><h4>2</h4></div>
              </Marker>

              <Marker latitude={30.78} longitude={-80.41} offsetLeft={-20} offsetTop={-10}>
              <div class="pin"><h4>1</h4></div>
              </Marker>
                
            </MapGL>
            <DeckGL {...viewport} layers={[searchResultLayer]} />
            {/* <GeolocateControl
                style={geolocateStyle}
                positionOptions={{enableHighAccuracy: true}}
                trackUserLocation={true}
                position='top-right'
                /> */}
        </div>
      )
    }
}

export default Map;