import React, { Component } from 'react';

class SurfBoards extends Component {
    render(){
     
      return (
        <div class="route-container">
            <h1>SurfBoards</h1>
        </div>
      )
    }
}

export default SurfBoards;