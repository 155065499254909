import './App.css';
import Map from './Map';
import HowToRide from './HowToRide';
import Rent from './Rent';
import Shop from './Shop';
import Surfboards from './Surfboards';
import Paddleboards from './Paddleboards';
import About from './About';
import Impact from './Impact';
import ContactUs from './ContactUs';
import Partner from './Partner';
import { Route, Switch, BrowserRouter } from 'react-router-dom';


function App() {
  return (
    <div>
    <div className="App has-squada-font">
      <nav class="navbar navbar-light justify-content-between">
        <a class="navbar-brand" href="/">Waveloco</a>
        <div>
          <div><a class="navbar-brand">Ride {">"}</a></div>
        </div>
      </nav>

      <div class="image-container">
        <div class="px-3 d-flex justify-content-start">
        <h1>Loco One</h1>
        </div>
        <br />
        <div class="px-3 d-flex justify-content-start">
        <h6>A new kind of surfboard </h6>
        </div>
        <br />
        <div class="px-3 d-flex justify-content-start">
        <button class="surf-button">See it Surf</button>
        </div>

      </div>

      <div class="image-container2">
        <div class="px-3 d-flex justify-content-start">
        <h1>Beaches</h1>
        </div>
        <br />
        <div class="px-3 d-flex justify-content-start">
        <h6>Surf in X Beaches worldwide</h6>
        </div>
        <br />
        <div class="px-3 d-flex justify-content-start">
        <button class="surf-button">SURF</button>
        </div>

      </div>


      <BrowserRouter >
        <Switch>
          <Route exact path="/howtoride" component={HowToRide} />
          <Route exact path="/map" component={Map} />
          <Route exact path="/rent" component={Rent} />
          <Route exact path="/shop" component={Shop} />
          <Route exact path="/surfboards" component={Surfboards} />
          <Route exact path="/paddleboards" component={Paddleboards} />
          <Route exact path="/about" component={About} />
          <Route exact path="/impact" component={Impact} />
          <Route exact path="/contactus" component={ContactUs} />
          <Route exact path="/partner" component={Partner} />
        </Switch>
      </BrowserRouter>

    
    </div>

<div class="footer has-squada-font">
<div class="d-flex justify-content-around col-md-6 offset-md-3 ">
  <div>
    <div>
    <h3 class="text-left">Rider</h3>
    </div>
    <div>
      <h6 class="text-left"><a href="/howtoride">How to Ride</a></h6>
    </div>
    <div>
      <h6 class="text-left"><a href="/map">Map</a></h6>
    </div>
    <div>
      <h6 class="text-left"><a href="/rent">Rent</a></h6>
    </div>
    <div>
      <h6 class="text-left"><a href="/shop">Shop</a></h6>  
    </div>
  </div>

  <div>
    <div>
    <h3 class="text-left">Boards</h3>
    </div>
    <div>
      <h6 class="text-left"><a href="/surfboards">Surfboards</a></h6> 
    </div>
    <div>
      <h6 class="text-left"><a href="/paddleboards">Paddleboards</a></h6> 
    </div>
  </div>

  <div>
    <div>
    <h3 class="text-left">Company</h3>
    </div>
    <div>
      <h6 class="text-left"><a href="/about">About</a></h6> 
    </div>
    <div>
      <h6 class="text-left"><a href="/impact">Impact</a></h6> 
    </div>
    <div>
      <h6 class="text-left"><a href="/contactus">ContactUs</a></h6> 
    </div>
    <div>
      <h6 class="text-left"><a href="/partner">Partner</a></h6> 
    </div>
  </div>

</div>
<div class="d-flex justify-content-end">
  <div>
    <i class="fa fa-instagram"></i>
  </div>
  <div>
    <i class="px-3 fa fa-twitter"></i>
  </div>
</div>
</div>    

</div>
  );
}

export default App;
